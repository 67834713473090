import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FaArrowRight } from "react-icons/fa";
import * as Yup from 'yup'; 
import axios from 'axios';

const Footer = () => {

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required('Required'),
  });
  

  const onSubmit = async (values, { setSubmitting, resetForm }) => {

	  // Merge custom value with form values
	  const dataToSend = {
		  ...values,
		  source: 'newsletter'
	  };

	  // Make the request and await the response
	  const response = await axios.post('http://localhost:3000/insert', dataToSend);
	//   console.log(response); // Log the response after the request is completed

	  




	 
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/subscribe`, {
      email: values.email,
    })
    .then((response) => {
      alert("Subscription successful!");
      setSubmitting(false);
      resetForm();
    })
    .catch((error) => {
      console.log(error); 
      alert("An error occurred, please try again later.");
      setSubmitting(false);
    });
  };


/* old contact and navigation in footer
  <div className='md:flex md:grid-cols-2 justify-between justify-items-stretch items-stretch w-full mx-auto pb-4'>
        
        

      <div className='flex grid-cols-2 gap-8'>
        <div className='flex-grow'>
        <h5 className='py-1'>Quick Links</h5>
          <ul>
            <li className='py-1'><a href="/">Home</a></li>
            <li className='py-1'><a href="/news">News</a></li>
            <li className='py-1'><a href="/contact">Contact</a></li>
          </ul>
          </div>
        <div className='flex-shrink' >
          <h5 className='py-1'>Contact Us</h5>
        <div className='flex grid-cols-2 gap-8'>
          <ul>
            <li className='py-1'><a href="mailto:info@oddmock.com" target='_blank'>Email</a></li>
            <li className='py-1'><a href="https://github.com/oddmock" target='_blank'>GitHub</a></li>
            <li className='py-1'><a href="https://www.instagram.com/oddmock/?hl=fr" target='_blank'>Instagram</a></li>
          </ul>
         <ul>
            <li className='py-1'><a href="https://www.linkedin.com/in/oddmock" target='_blank'>LinkedIn</a></li>
            <li className='py-1'><a href="https://twitter.com/oddmock" target='_blank'>Twitter</a></li>
          </ul>
        </div>
        </div>
      </div>
     </div>
     */

  return (
    <div className='w-full px-8 py-4  flex flex-col bg-[#FAFAFA] border-t-[1px] border-black'>
    <div className='block md:flex grid-cols- align-middle items-center justify-between   w-full mx-auto'>
    <div className='block'>
          <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form className=''>
            <div className='block md:pb-0 pb-4'>
              <h5 className='pb-2'>Sign Up For Our Newsletter</h5>
              <div className='flex align-middle'>
                <Field
                  className='px-4 py-2 align-middle border border-[#999999] text-black text-sm placeholder-black'
                  type="text"
                  name="email"
                  placeholder="Email"
                />
                <button type="submit" disabled={isSubmitting} className='text-white text-sm align-middle border-4 border-black bg-black px-2 py-2'>
                  <FaArrowRight />
                </button>
              </div>
              <ErrorMessage name="email" component="div" className='flex pt-2 text-[#ff3737]' />
              
            </div>
          </Form>
        )}
      </Formik>
    </div>

      <div className='block md:pb-0 pb-4'> 
        <h5>© ODDMock LLC 2024</h5>
      </div>
      <div className='block md:pb-0 pb-2 '> 
        <h5>Terms Of Service</h5>
      </div>
    </div>

    </div>
  )
}

export default Footer