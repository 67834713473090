import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import axios from 'axios';


const Contact = () => {
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
	  source: 'contactus',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      message: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
		// return;
		// const updatedValues = { ...values, source: values.source };

		const response = await axios.post('http://localhost:3000/insert', values);
		// console.log(response);



      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/contact`, values);
        if (response.status === 200) {
          alert('Email sent successfully');
        } else {
          alert('Failed to send email');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('Error sending email');
      }
    },
  });

//   console.log(process.env.REACT_APP_BACKEND_URL);

  return (
    <div className="flex flex-col justify-center items-center h-full mt-[-20px]">
      <div className="w-full max-w-7xl p-8 space-y-6   rounded-xl">
        <div className="pb-2 mb-8 border-b border-[#999999]">
          <h1>Contact</h1>
        </div>
        <form onSubmit={formik.handleSubmit} className="space-y-6">
          <div>
            <input
              id="name"
              name="name"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.name}
              placeholder="Full Name"
              className="mt-1 block w-full border-b px-4 border-gray-300 bg-transparent py-2 focus:ring-0 focus:border-black"
            />
          </div>

          <div>
            <input
              id="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              placeholder="Email"
              className="mt-1 block w-full border-b px-4 border-gray-300 bg-transparent py-2 focus:ring-0 focus:border-black"
            />
          </div>

          <div>
            <textarea
              id="message"
              name="message"
              onChange={formik.handleChange}
              value={formik.values.message}
              placeholder="Message"
              className="mt-1 block w-full border border-gray-300 bg-transparent p-4 rounded-md h-64 focus:ring-0 focus:border-black"
            />
          </div>

          <button type="submit" className="inline-flex justify-center py-3 px-8 border border-transparent font-medium rounded-xl text-white bg-black hover:bg-white hover:border-black hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black">
            Submit Message
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
